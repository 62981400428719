.loginPage {
    background: url("/src/Images/Picture1.jpg");
    /* background: linear-gradient(rgba(11, 11, 11, 0.5), rgba(10, 10, 10, 0.5)), url("src/Images/Picture1.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
}

.loginForm {
    margin-top: 50%;

    color: white;
}
/* Header component */
.header-wrapper {
    display: flex;
    align-items: center;
    margin-left: 400px;
  }
  
.employee_image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.empImg {
    width: 400px;
    height: 400px;
    border-radius: 50%;
}

.heading {
    font-size: 150px;
 }

.sub-heading {
    margin-top: 15px;
    padding-left: 0px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
   
    /* Adjust the line height as needed */
}

/* Responsive styles for smaller screens */
@media (max-width: 767px) {
    .heading {
        margin-top: 30%;
        font-size: 50px;
        /* Adjust font size for smaller screens */
    }

    .sub-heading {
        margin-top: 35%;
        padding-left: 1px;
        font-size: 10px;
        /* Adjust font size for smaller screens */
    }
}




.main {
    padding-top: 7%;
}

.footer {

    color: #fff;
    /* Text color */
    padding: 30px 0;
    /* Adjust the padding as needed */
    position: fixed;
    bottom: 0;
    width: 100%;
}

.footer p {
    padding-top: 5%;
    margin-left: 7%;
    /* Remove default margin */
    font-size: 20px;
}

.footer img {
    margin-left: 60%;
    max-height: 400px;
    width: 30%;
}

/* Responsive styles for smaller screens */

@media (max-width: 767px) {
    .main {
        margin-top: 0px;
        padding-top: 0%;
    } 

    .footer {
        margin-top: 10%;
        color: #fff;
       
        padding: 7px 0;
     
        position: relative;
        bottom: 0;
        width: 100%;
    }

    .footer p {
        margin-left: 2px;
        font-size: 12px;
    }

    .footer img {
        margin-left: 20px;
        width: 100%;
    }
}

.btn-primary {
    width: 90%;
    /* Make the button take full width */
}

/* Style for the button row */
.mb-3>div {
    margin-bottom: 10px;
    /* Adjust the bottom margin as needed */
}








