.user-information-title {
    color: cadetblue;
    font-size: 50px;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 50px;
}

.user-dashboard-table {
    margin-top: 40px;
    border: 6px solid yellowgreen;
}


.user-dashboard {
    display: flex;
  }
    
 .main-content {
   flex-grow: 1; 
  }
  


  /* User DASHBOARD */



  .user-dashboard-card-11 {
    background: #fff;
    height: auto;
    width: 100%;
    /* border: 2px solid yellow; */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    justify-items: center;
    margin-left: 2px;
    margin-top: 0px;
  }


  .user-dashboard-card-12 {
    background: #fff;
    height: auto;
    width: 100%;
    /* border: 2px solid yellow; */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    justify-items: center;
    margin-left: 2px;
    margin-top: 1px;

  }



 
 .w-row:before,.w-row:after{
    content:" ";
    display:table;
}
 .w-row:after{
    clear:both;
}
 .w-col{
    position:relative;
    float:left;
    width:100%;
    min-height:1px;
    padding-left:10px;
    padding-right:10px;
    
}
 .w-col-3{
    width:25%;
    
}
 @media screen and (max-width:767px){
     .w-row{
        margin-left:0;
        margin-right:0;
    }
     .w-col{
        width:100%;
        left:auto;
        right:auto;
    }
}
 @media screen and (max-width:479px){
     .w-col{
        width:100%;
    }
}

 .divider{
    height:1px;
    margin-top:20px;
    margin-bottom:0px;
    background-color:#eee;
}
 .style-label{
    color:#bebebe;
    font-size:12px;
    line-height:20px;
    font-weight:500;
    text-transform:uppercase;
}
 .tag-wrapper{
    margin-top:35px;
    margin-bottom:35px;
    padding-right:5px;
    padding-left:5px;
   
}
 .row{
    margin-bottom:0px;
    
}
 .number-card-number{
    margin-top:0px;
    margin-bottom:0px;
    color:#ffffff;
    font-weight:450;
    font-size: 25px;
    
}
 .tagline{
    font-size:12px;
    font-weight:500;
    letter-spacing:2px;
    text-transform:uppercase;
}
 .tagline.number-card-currency{
    color:#fff;
}
 .basic-column{
    padding-right:5px;
    padding-left:5px;
}
 .number-card{
    padding:22px 30px;
    border-radius:8px;
height: 150px;
    background-image:-webkit-linear-gradient(270deg, #9A1B15, #9A1B15);
    background-image:linear-gradient(180deg, #9A1B15, #9A1B15);
}
 .number-card.number-card-content3{
    background-image:-webkit-linear-gradient(270deg, #530095, #530095);
    background-image:linear-gradient(180deg, #530095, #530095);
}
 .number-card.number-card-content4{
    background-image:-webkit-linear-gradient(270deg, #ff8308, #fd4f00);
    background-image:linear-gradient(180deg, #ff8308, #fd4f00);
}
 .number-card.number-card-content2{
    display:block;
    background-image:-webkit-linear-gradient(270deg, #17cec4, #17cec4 0%, #08aeea);
    background-image:linear-gradient(180deg, #17cec4, #17cec4 0%, #08aeea);
    color:#333;
}
 .number-card.number-card-content1{
    background-image:-webkit-linear-gradient(270deg, #7042bf, #3023ae);
    background-image:linear-gradient(180deg, #7042bf, #3023ae);
}
 .number-card-progress-wrapper{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
    -ms-flex-pack:justify;
    justify-content:space-between;
}
 .number-card-divider{
    height:1px;
    margin-top:10px;
    margin-bottom:14px;
    background-color:hsla(0, 0%, 100%, .15);
}
 .number-card-dollars{
    color:hsla(0, 0%, 100%, .8);
    font-size:25px;
    line-height:24px;
    margin-top: 3%;
    /* text-align: center; */
}
 .number-card-progress{
    color:#fff;
    text-align:right;
}

/* //////////////// holiday card ///////// */
.number-card-divider1{
    height:1px;
    margin-top:5px;
    margin-bottom:14px;
    background-color:hsla(0, 0%, 100%, .15);
}
.number-card-dollars11{
    color:hsla(0, 0%, 100%, .8);
    font-size:16px;
    line-height:18px;
    margin-top: 3%;
    /* text-align: center; */
}
 .number-card-progress11{
    color:#fff;
    text-align:right;
}
 @media (max-width: 991px){
     .number-card-number{
        font-size:30px;
    }
     .number-card{
        padding-top:12px;
        padding-bottom:16px;
    }
}
.btn1 {
    background-image:-webkit-linear-gradient(270deg, #ff8308, #fd4f00);
    color: white;
    cursor: pointer;
    margin: 7px;
    padding: 5px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 10px 0px grey;
    width: 90px;
    height: 40px;
}

.btn2 {
    width: 90px;
    height: 40px;
    background-image:-webkit-linear-gradient(270deg, #787471, #787471);
    color: white;
    cursor: not-allowed;
    margin: 7px;
    padding: 5px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 10px 0px grey;
}

.btn-disable {
    background: gray;
    width: 100px;
    font-size: 16px;
    color: white;
    opacity: 0.5; /* Example disabled style (adjust as needed) */
    pointer-events: none; /* Disable click events */
    cursor: not-allowed; /* Show not-allowed cursor */
    margin-bottom: 10px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 10px 0px grey;
}

.btn-enable {
    background-image:-webkit-linear-gradient(270deg, #ff8308, #fd4f00);
    width: 100px;
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 10px 0px grey;
}

.tea-icon {
    font-size: 35px;
    color: #080c76;
    margin-left: 5px;
    margin-right: 5px;
}
.btn-Lunch-disable {
    background: gray;
    width: 110px;
    font-size: 16px;
    color: white;
    opacity: 0.5; /* Example disabled style (adjust as needed) */
    pointer-events: none; /* Disable click events */
    cursor: not-allowed; /* Show not-allowed cursor */
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 10px 0px grey;
}

.btn-Lunch-enable {
    background-image:-webkit-linear-gradient(270deg, #ff8308, #fd4f00);
    width: 110px;
    font-size: 16px;
    color: white;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 10px 0px grey;
}
.Lunch-icon {
    font-size: 35px;
    color: #080c76;
    margin-left: 8px;
    margin-right: 8px;
}

