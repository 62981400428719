.Blitz-admin{
     padding: 10px 20px;
     font-weight: bold;
     color: white;
     background-color: #E55A1B;
     border: none;
     border-radius: 5px;
     width: 60% ;
     align-items:center;
     margin-left:6px; 
     margin-bottom: 20px;
}

.admin-container{
    height: 300px;
     display: flex;
      flex-direction: column;
       justify-content: center;
       align-items: center;
       
     
}

.blitz-form{
     width: 300px;
      text-align: center;
}

.form-input{
   margin-bottom: 10px;
    padding: 8px;
     width: 100%;
}

.form-submit{
     padding: 10px 20px;
     font-weight: bold;
      color: white;
       background-color: #E55A1B;
       border: none;
       border-radius: 5px;
       width: 100%;
}

.txt{
     margin-top: 10px;
     color: #007bff;
     text-decoration: underline;
     cursor: pointer;
}
/* ---------------------- */

@media (max-width: 767px) {

.Blitz-admin{
     margin-bottom: 20px;
     
}

  
}
