.Accounts-dashboard {
    background: url("/src/Images/Lms-background.png");
    background: linear-gradient(rgba(11, 11, 11, 0.5), rgba(10, 10, 10, 0.5)), url("/src/Images/Lms-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 120%;
    height: 100vh;
}


.employee-login {
    padding: 10px;
    height: 500px;
    width: 500px;
    background-color: #230B54;
    margin-left: 950px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin-bottom: 20px;

}

.logo {
    width:fit-content;
    height: 100px;
}

.header-txt{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

}

.headingLms{
 margin-left: 50px;
 color: white;
 font-size:150px;
}

.sub-heading{
    margin-left: 40px;
}
.Admin-btn {
    background-color: #E55A1B; /* Change this to your desired color */
    color: white; /* Change this to your desired text color */
    border: none; /* Remove border or set your preferred border style */
  }
  
  .Admin-btn:hover {
    background-color: #aa5540; /* Optional: Change this to your desired hover color */
  }
  .User-btn {
    background-color: #E55A1B; /* Change this to your desired background color */
    color: white; /* Change this to your desired text color */
    border: none; /* Remove border or set your preferred border style */
  }
  
  .User-btn:hover {
    background-color: #aa5540; /* Change this to your desired hover color */
  }
  .Newuser-btn {
    background-color: #E55A1B; /* Change this to your desired background color */
    color: white; /* Change this to your desired text color */
    border: none; /* Remove border or set your preferred border style */
  }
  
  .Newuser-btn:hover {
    background-color: #aa5540; /* Change this to your desired hover color */
  }
/* -----------------------------------------------------------Responsive --------------------------------------------------- */
@media (max-width: 767px) {

    .employee-login {
        padding: 0px;
        text-align: center;
        margin-left: 5px;
        height: 420px;
        width: 380px;
        align-content: center;
        margin-top: 50px;
        
    }

    .Accounts-dashboard {
        background: url("/src/Images/Lms-background.png");
        background: linear-gradient(rgba(11, 11, 11, 0.5), rgba(10, 10, 10, 0.5)), url("/src/Images/Lms-background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* height: auto; */
        
    }
  
    .logo{
        align-items: left;
        margin-left: 2px;
        padding-left: 2px;
        width:200px;
    }

    .headingLms{
        font-size: 40px;
    }

    .sub-heading{
        margin-left: 10px;
        font-size: 15px;
    }


}