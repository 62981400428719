/* admindashboard.css */

.admin-dashboard {
  display: flex;
  /* border: 2px solid red; */
  background-color:antiquewhite;
  margin-top: 0px;
}

.main-content {
/* border: 2px solid red; */
padding: 50px;
/* border:  2px solid green; */
height: auto;


}

/* .table {
  width: 100%;
} */

.sidebar {

  padding: 20px;
}

/* Media query to adjust layout for smaller screens */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }
  
  .sidebar {
    width: 100%;
  }
}


/* //--------------------------------------------------------------------- */



/* admindashboard.css */

.card {
  width: 20rem; /* Increase the width of the cards */
  height: 5rem; /* Increase the height of the cards */
  margin-bottom: 0px; /* Add margin at the bottom to create space between cards and table */
  box-sizing:content-box;
  border-radius: 11px;
}

.table {
  margin-top: 20px; /* Add margin at the top of the table to create space between cards and table */
}

.logo{
  height:40px;
width: 120px;
/* border: 2px solid yellow; */

}

.leave-applications {
  margin: 0 auto; /* This will center the table horizontally */
  width: 80%; /* Adjust the width as needed */
}

.leave-applications h2 {
  text-align: center;
}

/* ????????????????????????????????????????????/ */
.wrapper{
  display: flex;
  justify-content: left;
  align-items: left;
  padding-top: 1%;
}
.user-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	padding: 40px;
	width: 610px;
	position: relative;
	overflow: hidden;
	box-shadow: 0 2px 20px -5px rgba(0,0,0,0.5);
}
 
.user-card:before {
   content: '';
   position: absolute;
   height: 300%;
   width: 173px;
   background: #07f9c0;
   top: -60px;
   left: -125px;
   z-index: 0;
   transform: rotate(17deg);

 }
 
 .user-card-img {
   display: flex;
   justify-content: center;
   align-items: center;   
   z-index: 3;
  
  

 }
 
 .user-card-img img {
   width: 200px;
   height: 200px;
   object-fit: cover;
   	border-radius: 50%;
     margin-right: 50%;
 }
 
 .user-card-info {
  margin-top: 5%;
   text-align: center;

 }
 
 .user-card-info h2 {
   font-size: 24px;
   margin: 0;
   margin-bottom: 10px;
   font-family: 'Bebas Neue', sans-serif;
   letter-spacing: 3px;
 }
 
 .user-card-info p {
   font-size: 14px;
   margin-bottom: 2px;
 }
 .user-card-info p span {
	font-weight: 700;
	margin-right: 10px;
}
 @media only screen and (min-width: 768px) {
   .user-card {
     flex-direction: row;
     align-items: flex-start;
   }   
   .user-card-img {
     margin-right: 20px;
     margin-bottom: 0;
   }
 
   .user-card-info {
     text-align: left;
   }
 }

 @media (max-width: 767px){
   .wrapper{
      padding-top: 3%;
   }
   .user-card:before {
      width: 300%;
      height: 200px;
      transform: rotate(0);
   }
   .user-card-info h2 {
      margin-top: 25px;
      font-size: 35px;
   }
   .user-card-info p span {
      display: block;
      margin-bottom: 15px;
      font-size: 18px;
   }
 }
 .pro-1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block; /* Remove any default inline layout */
  margin: 0 auto; /* Center the image horizontally */
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 50px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  
}
.profile-avatar-1 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
 
  color: black; /* or any other text color you want */
  margin-left: 20px;
}


