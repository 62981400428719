.admin-dashboard {
  display: flex;
  /* border: 2px solid red; */
  background-color:#aecfdb;
  margin-top: 0px;
  width: 100%;

}

.main-content {

  padding: 10px;
  height: auto;


}

.main-1{
  justify-content: center;
}

/* .table {
  width: 100%;
} */

.sidebar {

  padding: 10px;
}




/* / //--------------------------------------------------------------------- / */

/* .text-center {
   text-align: center;
}  */






.card {
  width: 20rem;
  height: 7rem;
  margin-bottom: 20px;
  box-sizing: content-box;
  border-radius: 11px;
}

.table {
  margin-top: 20px;
}

.logo {
  height: 40px;
  width: 120px;
  /* border: 2px solid yellow;  */

}

.leave-applications {
  margin: 0 auto;
  width: 80%;
}

.leave-applications h2 {
  text-align: center;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2px;

}

.dashboard-card-11 {
  background: #fff;
  height: 150px;
  width: 90%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-items: center;
  margin-left: 0%;
}

.dashboard-card-22 {
  justify-items: center;
  background: #fff;
  height: 150px;
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 90%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-items: center;
}

.dashboard-card h3 {
  margin-bottom: 10px;
}


.dashboard-cards-2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

.dashboard-card-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-items: center;
}

.dashboard-card-2 h2 {
  margin-bottom: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.dashboard-card-2 table {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-spacing: 0;
  background-color: #FFFFFF;
  font-size: 14px;
}

thead th {
  color: #100000;
  text-align: center;
  background-color: #F9FAFC;
}

thead th,
tbody td {
  padding: 14px;
  border: 0;
  border-bottom: 1px solid #E7E7E7;
  text-align: center;
}

tbody td:first-child {
  border-left: 4px solid transparent;
  transition: all ease 0.3s;
}

tbody tr:last-child td {
  border-bottom: 0;
}

tbody tr:hover {
  background-color: #F7F9FC;
}

tbody tr:hover td:first-child {
  border-left-color: #0F6FDE;
}

/* td.status {
  text-align: center;
} */

td.status span {
  font-size: 12px;
  border-radius: 3px;
  padding: 4px 8px;
}

td.status span.approved {
  color: #FFFFFF;
  background-color: #00C455;
  /* text-align: left; */
}

td.status span.decline {
  color: #FFFFFF;
  background-color: #F13426;
}

td.status span.pending {
  color: #132D4A;
  background-color: #EBF0F5;
}

#toggleView:checked~table thead {
  display: none;
}

#toggleView:checked~table tbody {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
}

#toggleView:checked table tbody tr,
#toggleView:checked table tbody td {
  display: block;
  border: 0;
}

#toggleView:checked~table tbody td {
  padding: 4px;
  font-weight: bold;
}

#toggleView:checked~table tbody td:before {
  content: attr(data-name);
  width: 100px;
  display: inline-block;
  text-transform: capitalize;
  font-weight: normal;
}

#toggleView:checked~table tbody td.status {
  position: absolute;
  top: 4px;
  right: 4px;
}

#toggleView:checked~table tbody tr {
  position: relative;
  width: calc(50% - 8px);
  border: 1px solid #E7E7E7;
  padding: 8px;
  margin: 4px;
}

.dashboard-cards-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}

.dashboard-card-3 {
  background: #fff;
  height: auto;
  width: auto;
  margin-top: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  justify-items: center;
}
.pro {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block; /* Remove any default inline layout */
  margin: 0 auto; /* Center the image horizontally */
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 50px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  
}
.profile-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
 
  color: black; /* or any other text color you want */
  margin-left: 60px;
}
.break-1{
  overflow: hidden;
  width: 150px;
}
.break-2{
  overflow: hidden;
  width: 150px;
}

.w-row:before,.w-row:after{
  content:" ";
  display:table;
}
.w-row:after{
  clear:both;
}
.w-col{
  position:relative;
  float:left;
  width:100%;
  min-height:1px;
  padding-left:10px;
  padding-right:10px;
  
}
.w-col-3{
  width:25%;
  
}
@media screen and (max-width:767px){
   .w-row{
      margin-left:0;
      margin-right:0;
  }
   .w-col{
      width:100%;
      left:auto;
      right:auto;
  }
}
@media screen and (max-width:479px){
   .w-col{
      width:100%;
  }
}

.divider{
  height:1px;
  margin-top:20px;
  margin-bottom:0px;
  background-color:#eee;

}
.style-label{
  color:#bebebe;
  font-size:12px;
  line-height:20px;
  font-weight:500;
  text-transform:uppercase;

}
.tag-wrapper{
  margin-top:0px;
  margin-bottom:15px;
  padding-right:5px;
  padding-left:5px;
}
.row{
  margin-bottom:0px;
  
}
.number-card-number1{
  text-align: center;
  margin-top:0px;
  margin-bottom:0px;
  color:#ffffff;
  font-weight:450;
  font-size: 25px;
  
}
.number-card-number2{
  text-align: left;
  margin-top:0px;
  margin-bottom:0px;
  color:#ffffff;
  font-weight:450;
  font-size: 20px;
  
}
.tagline{
  font-size:12px;
  font-weight:500;
  letter-spacing:2px;
  text-transform:uppercase;

}
.tagline.number-card-currency{
  color:#fff;
}
.basic-column{
  padding-right:5px;
  padding-left:5px;
}
.number-card{
  padding:22px 30px;
  border-radius:8px;
height: 150px;
  background-image:-webkit-linear-gradient(270deg, #9A1B15, #9A1B15);
  background-image:linear-gradient(180deg, #9A1B15, #9A1B15);
}
.number-card.number-card-content3{
  background-image:-webkit-linear-gradient(270deg, #530095, #530095);
  background-image:linear-gradient(180deg, #530095, #530095);
}
.number-card.number-card-content4{
  background-image:-webkit-linear-gradient(270deg, #ff8308, #fd4f00);
  background-image:linear-gradient(180deg, #ff8308, #fd4f00);
}
.number-card.number-card-content2{
  display:block;
  background-image:-webkit-linear-gradient(270deg, #17cec4, #17cec4 0%, #08aeea);
  background-image:linear-gradient(180deg, #17cec4, #17cec4 0%, #08aeea);
  color:#333;
}
.number-card.number-card-content1{
  background-image:-webkit-linear-gradient(270deg, #7042bf, #3023ae);
  background-image:linear-gradient(180deg, #7042bf, #3023ae);
}
.number-card-progress-wrapper{
  margin-top: 5%;
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -webkit-justify-content:space-between;
  -ms-flex-pack:justify;
  justify-content:space-between;
}
.number-card-divider{
  height:1px;
  margin-top:5px;
  margin-bottom:1px;
  background-color:hsla(0, 0%, 100%, .15);
}
.number-card-dollars1{
  color:hsla(0, 0%, 100%, .8);
  font-size:20px;
  line-height:24px;
  margin-top: 3%;
font-weight: 450;
  text-align: center;
}
.number-card-progress{
  color:#fff;
  text-align:right;

}
/* ?????????????????????????? */
.number-card-dollars33{
  color:hsla(0, 0%, 100%, .8);
  font-size:16px;
  line-height:18px;
  margin-top: 3%;

}
.number-card-progress33{
  color:#fff;
  text-align:right;

}
@media (max-width: 991px){
   .number-card-number{
      font-size:30px;
  }
   .number-card{
      padding-top:12px;
      padding-bottom:16px;
  }
}